<template>
  <div class="master-login-container">
    <img src="@/assets/logo.png" alt="">
    <div class="login-form">
      <form @submit.prevent="HandleLogin">  <!-- form 제출 시 HandleLogin 호출 -->
        <h1>로그인</h1>
        <div class="label-box">
          <label>
            아이디
          </label>
          <input type="text" placeholder="아이디 입력" class="custom-input-box" v-model="id">
        </div>
        <div class="label-box">
          <label>
            패스워드
          </label>
          <input type="password" placeholder="패스워드 입력" class="custom-input-box" 
                 v-model="password" v-on:keypress.enter.prevent="HandleLogin" autoComplete="off">
        </div>
        <div class="login-buttons" >
          <button type="button" class="custom-btn-box" @click="setUserType('student')">수강생 로그인</button>
          <button type="button" class="custom-btn-box admin-login-button" @click="setUserType('admin')">관리자 로그인</button>          
        </div>
      </form>
    </div>
    <p class="footer-title">COPYRIGHT(C) 2021 ASSETTA. ALL RIGHT RESERVED.</p>
    <Progress :isProgress="isProgress"/>
  </div>
</template>

<script>
import Progress from "@/components/Progress";
import { sendWorkLog } from "@/lib/logModule";
//import CryptoJS from "crypto-js";
import axios from "@/axios";
//import { mapActions } from "vuex/dist/vuex.common.js";

export default {
  name: "gate",
  components: { Progress },
  data() {
    return {
      id: '',
      password: '',
      isProgress: false,
      loginUserType: null, 
    }
  },
  methods: {
    //...mapActions(['updateAdminMode']),
    setUserType(type) {
      this.loginUserType = type;  // 사용자 역할 설정
      this.HandleLogin();  // 로그인 프로세스 실행
    },
    HandleLogin() {
      if (this.loginUserType === 'admin') {
        this.AdminLogin();
      } else if (this.loginUserType === 'student') {
        this.UserLogin();
      } else {
        // 역할이 설정되지 않은 경우 처리
        this.$swal.fire({ title: '로그인 오류', text: '사용자 역할을 선택해주세요.', icon: 'error' });
      }
    },
    AdminLogin() {
      const self = this;

      const data = JSON.stringify({
        "id": self.id,
        "password": self.password
      });

      const config = {
        method: 'post',
        url: '/auth/admin',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(res => {
          sessionStorage.clear();
          sessionStorage.token = JSON.stringify(res.data);
          //console.log("로그인 응답 확인:", res.data);

          // const encrypted = res.data.user_info;
          // const key = process.env.VUE_APP_USER_DECRYPT_KEY || "my-secret-key"; // 백엔드와 동일한 키
          // const decrypted = CryptoJS.AES.decrypt(encrypted, key).toString(CryptoJS.enc.Utf8);
          //console.log("복호화된 user_info:", decrypted);
          //const user = JSON.parse(decrypted);
          const user = res.data.user_info;
        
          const userInfo = {
            userid: user.userid,
            username: user.username,
            userRole: user.role, 
            type: user.type,
            loginTime: new Date().toISOString()
          };
                    
          sessionStorage.setItem("userName", userInfo.username);
          sessionStorage.setItem("userRole", userInfo.type);
          sessionStorage.setItem("admin_type", userInfo.type); 
          sessionStorage.setItem("loginTime", userInfo.loginTime);
          // console.log("userInfo.type:"+userInfo.type);
           console.log("userInfo.userid:"+userInfo.userid)
          
          sessionStorage.setItem("adminId", userInfo.userid);          
        
          
          //this.updateAdminMode(true);
          
                    
          sendWorkLog('000100');
          if (this.$route.path !== '/home') {
            this.$router.push('/home').catch(err => {
              if (err.name !== 'NavigationDuplicated' && err.message !== 'Navigation cancelled') {
                console.error(err);
              }
            });
          }
         
        })
        .catch((error) => {
          const statusCode = error.response?.status || 'Unknown Status';
          const errorMessage = error.response?.data?.message || '계정 정보를 확인하세요.';
          self.$swal.fire({ title: `로그인 실패(${statusCode})`, text: errorMessage, icon: 'error' });
        });
    },
    UserLogin() {
      const self = this;

      const data = JSON.stringify({
        "id": self.id,
        "password": self.password
      });

      const config = {
        method: 'post',
        url: '/auth/user',  // 수강생 로그인 API 엔드포인트
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(res => {
          sessionStorage.clear();
          sessionStorage.token = JSON.stringify(res.data);
          sessionStorage.setItem('userId', self.id);
          //self.$router.push('/UserHome');  // 수강생 홈으로 이동
          if (this.$route.path !== '/UserHome') {
            this.$router.push('/UserHome');
          }
        })
        .catch(() => {
          self.$swal.fire({ title: '로그인 실패', text: '계정 정보를 확인하세요.', icon: 'error' });
        });
    }
  }
}
</script>

<style scoped>
.master-login-container {
  font-weight: 500;
  background: #e6f2ff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 148px;
}

.master-login-container img {
  margin-bottom: 40px;
}

.master-login-container .login-form {
  margin: 0 auto;
  width: 418px;
  height: 376px;
  background: #ffffff;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 40px 20px;
}

.master-login-container .login-form h1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

.master-login-container .label-box label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272833;
  text-align: left !important;
}

.master-login-container .label-box {
  text-align: left;
  margin-top: 20px;
}

.master-login-container .label-box input {
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  margin-bottom: 0;
}

.master-login-container .custom-btn-box {
  margin: 40px 0;
  background: #4768ee;
}

.master-login-container .footer-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #757ca1;
  margin-top: 40px;
}

.custom-input-box {
  padding: 12px 8px;
  height: 40px;
  background: #FFFFFF;
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
}

.custom-input-box::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
}
.login-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.custom-btn-box {
  /* width: 100%; */
  padding: 5px 20px;

  height: 40px;
  background: #4C9AF5;
  border: 0;
  font-family: 'NotoSansKR', serif;
  font-weight: 700;
  border-radius: 4px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}
@media (max-width: 768px) {
  .login-buttons {
    justify-content: center;
  }

  .admin-login-button {
    display: none !important; /* 관리자 로그인 버튼 숨기기 */
  }

  /* 수강생 로그인 버튼을 중앙에 배치 */
  .custom-btn-box {
    margin: 0 auto;
    width: 100%;
    max-width: 200px;
  }

  /* 전체 배경과 로그인 박스 위치 조정 */
  .master-login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 0 15px;
    background-color: #e6f2ff;
    overflow: hidden; /* 스크롤 없애기 */
  }

  .login-form {
    width: 100%;
    max-width: 300px;
    padding: 10px; /* 내부 여백을 줄여 세로 길이 감소 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: white;
    margin: 0 auto;
    box-sizing: border-box;  
  }

  /* 로그인 창의 세로 길이 조절 */
  .login-form h1 {
    margin-bottom: 10px;
  }

  .label-box {
    margin-bottom: 8px;
  }

  /* 버튼 아래 여백 조절 */
  .login-buttons {
    margin-bottom: 10px;
  }

  /* 푸터 위치 조정 */
  .footer-title {
    text-align: center;
    margin-top: 10px;
  }
}

</style>
